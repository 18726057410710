"use client";

import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { report } from "@/utils/analytics/logging";
import { themeColors } from "@/utils/themeColors";
import dayjs from "dayjs";
import { useEffect } from "react";

function cleanStackTrace(error) {
    if (!error.stack) return error.message;

    return error.stack
        .split("\n")
        .filter(line => line.includes("localhost") && !line.includes("node_modules")) // Keep relevant lines
        .map(line => line.replace(/\(http:\/\/localhost:\d+\/_next\/static\/chunks\/.+?:/, "(")) // Shorten paths
        .join("\n");
}

export default function ErrorPage({ error }: { error: Error }) {
    useEffect(() => {
        report(error, window.location.href);
    }, [error]);

    return (
        <FlexColumnAlignJustifyCenter
            style={{
                textAlign: "center",
                backgroundColor: themeColors.background,
                height: "calc(99vh - var(--navbar-height))",
                maxWidth: "66rem",
                margin: "auto",
            }}>
            <h1 style={{ fontSize: "4rem", fontWeight: "500" }}>
                Not Found <span style={{ color: "#999", fontSize: "0.65em" }}>Error 404</span>
            </h1>
            <blockquote style={{ fontSize: "2.8rem", fontStyle: "italic" }}>
                <p style={{ position: "relative" }}>
                    <span
                        style={{
                            color: "#999",
                            fontSize: "3em",
                            fontFamily: "Georgia, serif",
                            position: "absolute",
                            left: "-0.37em",
                            top: -20,
                        }}>
                        “
                    </span>
                    Knowt can do many things, but it cannot show you a webpage when it does not exist!
                </p>
                <figcaption style={{ color: "#999", fontSize: "0.7em", marginTop: "2rem" }}>
                    — Albert Einstein
                </figcaption>
            </blockquote>
            {/* show error information so it can be easily sent in a screenshot */}
            <div style={{ color: "#999" }}>
                <div>Error: {error.message}</div>
                <div>Stack: {cleanStackTrace(error)}</div>
                <div>Location: {window.location.href}</div>
                <div>Timestamp: {dayjs().format("YYYY-MM-DD HH:mm:ss A Z")}</div>
            </div>
            <footer style={{ color: "#999", position: "fixed", bottom: 0, margin: "1rem" }}>
                Technical Contact:{" "}
                <a style={{ color: themeColors.neutralBlack, textDecoration: "none" }} href="mailto:contact@knowt.com">
                    contact@knowt.com
                </a>
            </footer>
        </FlexColumnAlignJustifyCenter>
    );
}
