import { __DEV__ } from "@/lib/constants";
import packageInfo from "@/package.json";
import type { UserDetails } from "@knowt/syncing/graphql/schema";
import { deepScrapeEmptyFields } from "@knowt/syncing/utils/dataCleaning";
import { prettyPrint } from "@knowt/syncing/utils/stringUtils";
import * as Sentry from "@sentry/nextjs";

export const setUser = (user: UserDetails) => {
    Sentry.setUser(deepScrapeEmptyFields({ ...user }));
};

export const log = (key, ...extras) => {
    if (__DEV__ || packageInfo.development) {
        // biome-ignore lint/suspicious/noConsole:
        console.log(key, ...extras);
    }
};

export const forceLog = (key, ...extras) => {
    // biome-ignore lint/suspicious/noConsole:
    console.log(key, ...extras);
};

export const warn = (key, ...extras) => {
    if (__DEV__ || packageInfo.development) {
        // biome-ignore lint/suspicious/noConsole:
        console.warn(key, ...extras);
    }
};

export const forceWarn = (key, ...extras) => {
    // biome-ignore lint/suspicious/noConsole:
    console.warn(key, ...extras);
};

export const report = (error: unknown, location: string, input?: { [key: string]: unknown }) => {
    if (__DEV__ || packageInfo.development) {
        // biome-ignore lint/suspicious/noConsole:
        console.error("ERROR", location, error, input);
    }

    const additionalInfo: Record<string, any> = {
        input: prettyPrint(input),
        error,
    };

    Sentry.withScope(scope => {
        scope.setTag("error_location", location);
        for (const key in additionalInfo) {
            if (additionalInfo[key]) {
                scope.setExtra(key, additionalInfo[key]);
            }
        }
        Sentry.captureException(error);
    });
};

export const onAppsyncError = ({ status }) => {
    if (status === 405) {
        // captcha
    }
};
