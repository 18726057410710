import clsx from "clsx";
import React, { ElementType, ReactElement } from "react";
import styles from "./flex.module.css";

type FlexProps<T extends React.ElementType> = React.ComponentPropsWithoutRef<T> & {
    as?: T;
    children?: React.ReactNode;
    className?: string;
    ref?: React.RefObject<HTMLElement | null>;
};

export type PolymorphicComponent = <T extends ElementType = "div">(props: FlexProps<T>) => ReactElement<any> | null;
export type PolymorphicComponentWithDisplayName = PolymorphicComponent & { displayName?: string };

export const Flex: PolymorphicComponentWithDisplayName = <T extends ElementType>({ ref, ...props }: FlexProps<T>) => {
    const { as, children, className, ...rest } = props;
    const Element = as || "div";
    return (
        <Element ref={ref} className={clsx(styles.flex, className)} {...rest}>
            {children}
        </Element>
    );
};

export const FlexRowAlignCenter: PolymorphicComponentWithDisplayName = <T extends ElementType>({
    ref,
    ...props
}: FlexProps<T>) => {
    const { className, children, ...rest } = props;
    return (
        <Flex ref={ref} className={clsx(styles.flexRow, styles.alignItemsCenter, className)} {...rest}>
            {children}
        </Flex>
    );
};

export const FlexColumn: PolymorphicComponentWithDisplayName = <T extends ElementType>({
    ref,
    ...props
}: FlexProps<T>) => {
    const { className, children, ...rest } = props;
    return (
        <Flex ref={ref} className={clsx(styles.flexColumn, className)} {...rest}>
            {children}
        </Flex>
    );
};

export const FlexColumnJustifyCenter: PolymorphicComponentWithDisplayName = <T extends ElementType>({
    ref,
    ...props
}: FlexProps<T>) => {
    const { className, children, ...rest } = props;
    return (
        <Flex ref={ref} className={clsx(styles.flexColumn, styles.justifyContentCenter, className)} {...rest}>
            {children}
        </Flex>
    );
};

export const FlexColumnAlignJustifyCenter: PolymorphicComponentWithDisplayName = <T extends ElementType>({
    ref,
    ...props
}: FlexProps<T>) => {
    const { className, children, ...rest } = props;
    return (
        <Flex
            ref={ref}
            className={clsx(styles.flexColumn, styles.alignItemsCenter, styles.justifyContentCenter, className)}
            {...rest}>
            {children}
        </Flex>
    );
};
